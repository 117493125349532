/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { tn_logo, logo_white, logo_black } from 'helpers/images'
import { useTranslation } from "react-i18next"
import Switch from "react-switch";

const WebHeaderAlt = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { t, i18n } = useTranslation()
    const { language } = i18n
    const [page, setPage] = useState(false);
    const [switchChecked, setStateChecked] = useState(false);
    const [lang, setLang] = useState("en");
    const [loc, setLoc] = useState(null);

    const handleChange = state => {
        let tLang = lang;
        let tLoc = loc;
        if (lang === "en") {
            tLang = "ta"
            tLoc = loc.replace('/en', '/ta')
        }
        else if (lang === "ta") {
            tLang = "en"
            tLoc = loc.replace('/ta', '/en')
        }
        i18n.changeLanguage(tLang)
        navigate(tLoc);
    };

    useEffect(() => {
        if (location.pathname === "/ta/home" || location.pathname === "/en/home" || location.pathname === "/ta/home/" || location.pathname === "/en/home/") {
            setPage(false)
        }
        else {
            setPage(true)
        }
        setLoc(location.pathname)
    }, [location]);

    useEffect(() => {
        if (language) {
            if (language === 'ta') {
                setStateChecked(true)
            }
            else if (language === 'en') {
                setStateChecked(false)
            }

            setLang(language)
        }
    }, [language]);

    return (
        <div className={page ? "kk-header-alt" : "kk-header-home"}>
            <Navbar collapseOnSelect expand="lg">
                <Container fluid>
                    <Row className="headfull-w">
                        <Col xs={6} sm={4} md={4}>
                            <Link to={`/${language}`} className="navbar-brand">
                                <img className="main-logo separtor" src={tn_logo} alt="" />
                                <img className="main-logo" src={page ? logo_black : logo_white} alt="" />
                            </Link>
                        </Col>
                        <Col xs={6} sm={8} md={8} className="text-end">
                            <Navbar.Toggle aria-controls="responsive-navbar-nav text-right" />
                            <div className="switch-wrapper-align">
                                <Switch
                                    onChange={handleChange}
                                    checked={switchChecked}
                                    offColor="#EBEBEB"
                                    onColor="#EBEBEB"
                                    offHandleColor="#7E7E7E"
                                    onHandleColor="#7E7E7E"
                                    uncheckedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "#000",
                                                paddingRight: 2
                                            }}>
                                            த
                                        </div>
                                    }
                                    checkedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "#000",
                                                paddingRight: 2
                                            }}>
                                            En
                                        </div>
                                    } />
                            </div>
                            <Navbar.Collapse className="justify-content-end">
                                <Nav>
                                    <NavLink className="nav-link" to={`/${language}/home`}>{language === "en" ? "Home" : "முகப்பு"}</NavLink>
                                    <NavLink className="nav-link" to={`/${language}/about`}>{language === "en" ? "About Us" : "நம்ம குமரி"}</NavLink>
                                    <NavDropdown title={language === "en" ? "Explore" : "விவரி"} id="collasible-nav-dropdown">
                                        <NavDropdown.Item as={NavLink} to={`/${language}/explore/temples`} >{language === "en" ? "Temples" : "கோவில்கள் "}</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to={`/${language}/explore/churches`} >{language === "en" ? "Churches" : "தேவாலயங்கள்"}</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to={`/${language}/explore/beaches`} >{language === "en" ? "Beaches" : "கடற்கரைகள் "}</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to={`/${language}/explore/mosques`} >{language === "en" ? "Mosques" : "மசூதிகள்"}</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to={`/${language}/explore/eco-tourisms`} >{language === "en" ? "Eco Tourism" : "இயற்கை சுற்றுலா"}</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to={`/${language}/explore/harbours`} >{language === "en" ? "Harbours" : "துறைமுகங்கள் "}</NavDropdown.Item>
                                        <NavDropdown.Item as={NavLink} to={`/${language}/explore/parks-and-forts`}>{language === "en" ? "Parks & Forts" : "பூங்காக்கள் & கோட்டைகள்"}</NavDropdown.Item>
                                    </NavDropdown>
                                    <div className="switch-wrapper">
                                        <Switch
                                            onChange={handleChange}
                                            checked={switchChecked}
                                            offColor="#EBEBEB"
                                            onColor="#EBEBEB"
                                            offHandleColor="#7E7E7E"
                                            onHandleColor="#7E7E7E"
                                            uncheckedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#000",
                                                        paddingRight: 2
                                                    }}>
                                                    த
                                                </div>
                                            }
                                            checkedIcon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        fontSize: 15,
                                                        color: "#000",
                                                        paddingRight: 2
                                                    }}>
                                                    En
                                                </div>
                                            } />
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        </div>
    )
}

export default WebHeaderAlt
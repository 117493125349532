import * as constant from "store/constants/WebConstants";
import { getLocalStorage } from 'helpers/LocalStorage'

const initState = {
    language: getLocalStorage('site_lang') === false ? 'en' : getLocalStorage('site_lang'),
    loading: false,
    mapLoading: false,
    sliderData: null,
    infoCounts: null,
    mapCategory: null,
    popularPlaces: null,
    infoContent: null,
    counts: null,
    getInTouchSuccess: null,
    metaInfo: null
}

const WebReducer = (state = initState, { type, response }) => {

    switch (type) {
        case constant.HOME_REQ_INIT:
        case constant.GET_TOUCH_INIT:
        case constant.POPULAR_PLACES_INIT:
        case constant.PLACE_INFO_INIT:
            return {
                ...state,
                loading: true,
            }

        case constant.MAP_REQ_INIT:
            return {
                ...state,
                mapLoading: true,
            }

        case constant.HOME_REQ_SUCCESS:
            return {
                ...state,
                loading: false,
                sliderData: response?.silder,
                metaInfo: response?.meta_details,
                sliderDataCount: {
                    "temples": response?.temple,
                    "parks": response?.park,
                    "falls": response?.falls
                }
            }
        case constant.MAP_REQ_SUCCESS:
            return {
                ...state,
                mapLoading: false,
                mapCategory: response?.info
            }
        case constant.GET_TOUCH_SUCCESS:
            return {
                ...state,
                loading: false,
                getInTouchSuccess: response
            }
        case constant.POPULAR_PLACES_SUCCESS:

            return {
                ...state,
                loading: false,
                popularPlaces: response?.info,
                exploreData: response,
                metaInfo: response.meta_details

            }
        case constant.PLACE_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                infoContent: response?.info,
                infoDetails: response,
                metaInfo: response.meta_details
            }

        case constant.HOME_REQ_FAILED:
        case constant.MAP_REQ_FAILED:
        case constant.GET_TOUCH_FAILED:
        case constant.POPULAR_PLACES_FAILED:
        case constant.PLACE_INFO_FAILED:
            return {
                ...state,
                loading: false,
                error: response,
            }

        default:
            return state;
    }
}

export default WebReducer
// Home Page
export const HOME_REQ_INIT = 'HOME_REQ_INIT'; 
export const HOME_REQ_SUCCESS = 'HOME_REQ_SUCCESS'; 
export const HOME_REQ_FAILED = 'HOME_REQ_FAILED'; 

export const MAP_REQ_INIT = 'MAP_REQ_INIT'; 
export const MAP_REQ_SUCCESS = 'MAP_REQ_SUCCESS'; 
export const MAP_REQ_FAILED = 'MAP_REQ_FAILED';

// About Page
export const GET_TOUCH_INIT = 'GET_TOUCH_INIT'; 
export const GET_TOUCH_SUCCESS = 'GET_TOUCH_SUCCESS'; 
export const GET_TOUCH_FAILED = 'GET_TOUCH_FAILED';

// Explore Page
export const POPULAR_PLACES_INIT = 'POPULAR_PLACES_INIT'; 
export const POPULAR_PLACES_SUCCESS = 'POPULAR_PLACES_SUCCESS'; 
export const POPULAR_PLACES_FAILED = 'POPULAR_PLACES_FAILED';

// Info Page
export const PLACE_INFO_INIT = 'PLACE_INFO_INIT'; 
export const PLACE_INFO_SUCCESS = 'PLACE_INFO_SUCCESS'; 
export const PLACE_INFO_FAILED = 'PLACE_INFO_FAILED';
import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import * as Constant from "store/constants/WebConstants";
import WebServices from 'store/services/WebServices';

export function* FetchHomeData(request) {
    try {
        const response = yield call(WebServices.FetchHomeData);
        yield put({ type: Constant.HOME_REQ_SUCCESS, response })
    } catch (error) {
        yield put({ type: Constant.HOME_REQ_FAILED, error })
    }
}
export function* FetchMapData(request) {
    try {
        const response = yield call(WebServices.FetchMapData, request.category);
        yield put({ type: Constant.MAP_REQ_SUCCESS, response })
    } catch (error) {
        yield put({ type: Constant.MAP_REQ_FAILED, error })
    }
}
export function* PostGetInTouch(request) {
    try {
        const response = yield call(WebServices.PostGetInTouch, request.formData);
        yield put({ type: Constant.GET_TOUCH_SUCCESS, response })
    } catch (error) {
        yield put({ type: Constant.GET_TOUCH_FAILED, error })
    }
}
export function* FetchPopularPlaces(request) {
    try {
        const response = yield call(WebServices.FetchPopularPlaces, request.category);
        yield put({ type: Constant.POPULAR_PLACES_SUCCESS, response })
    } catch (error) {
        yield put({ type: Constant.POPULAR_PLACES_FAILED, error })
    }
}
export function* FetchPlaceInfo(request) {
    try {
        const response = yield call(WebServices.FetchPlaceInfo, request.category, request.slug);
        yield put({ type: Constant.PLACE_INFO_SUCCESS, response })
    } catch (error) {
        yield put({ type: Constant.PLACE_INFO_FAILED, error })
    }
}

export default function* QuotationSaga() {
    yield takeLatest(Constant.HOME_REQ_INIT, FetchHomeData);
    yield takeLatest(Constant.MAP_REQ_INIT, FetchMapData);
    yield takeLatest(Constant.GET_TOUCH_INIT, PostGetInTouch);
    yield takeLatest(Constant.POPULAR_PLACES_INIT, FetchPopularPlaces);
    yield takeLatest(Constant.PLACE_INFO_INIT, FetchPlaceInfo);
}

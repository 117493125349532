import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { logo_white, govt_logo, namma_kumari } from 'helpers/images'

const WebFooter = () => {
    const { i18n } = useTranslation()
    const { language } = i18n

    return (
        <div className="kk-footer">
            <Container fluid>
                <Row>
                    <Col md={3} className="kk-footer-align">
                        <Link to={`/${language}`}>
                          <img className="kk-govt-logo" src={govt_logo} alt="" />
                        </Link>
                    </Col>
                    <Col md={6}>
                        <div className="text-center">
                        <Link to={`/${language}`}>
                            <img className="kk-tour-logo" src={logo_white} alt="" />
                        </Link>
                        </div>
                        <ul className="kk-quick-links">
                            <li>
                                <Link to={`/${language}/how-to-reach`}>How to Reach</Link>
                            </li>
                            <li>
                                <Link to={`/${language}/tourist-info`}>Tourist Information</Link>
                            </li>
                            <li>
                                <Link to={`/${language}/events`}>Events</Link>
                            </li>
                            <li>
                                <a target={"_blank"} rel="noreferrer" href="https://kanniyakumari.nic.in/">District Official Website</a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} className="text-md-end kk-footer-align">
                       <Link to={`/${language}`}>
                         <img className="kk-namma-kumari" src={namma_kumari} alt="" />
                        </Link>
                    </Col>
                </Row>
                <Row className="text-center mt-2">
                    <Col md={12}>
                        <p>© Content Owned And Maintained By Kanniyakumari District Tourism Department, Developed And Hosted By <a href="https://www.capecom.co.uk/" target="_blank" rel="noreferrer">Capecom Solutions Pvt. Ltd.</a></p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default WebFooter
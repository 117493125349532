import React, { lazy, Suspense, useEffect } from "react"
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import translations from "translations"
import WebLayout from 'views/layouts/WebLayout'
import PageLoader from 'views/shared/PageLoader'

const Home = lazy(() => import('./views/home'))
const About = lazy(() => import('./views/about'))
const Explore = lazy(() => import('./views/explore'))
const Info = lazy(() => import('./views/info'))
const HowToReach = lazy(() => import('./views/how-to-reach'))
const TouristInfo = lazy(() => import('./views/tourist-info'))
const Events = lazy(() => import('./views/events'))
const PageNotFound = lazy(() => import('./views/not-found'))

// const Home = lazy(() => {
//     return new Promise(resolve => {
//         setTimeout(() => resolve(import("./views/home")), 5000);
//     });
// });

function App() {
    const { i18n } = useTranslation()
    const loc = useLocation()

    const routes = () => {
        return (
            <>
                {/* test.com/ta -> test.com/ta/home */}
                <Route index element={<Navigate to={`/${i18n.language}/home`} />} />
                <Route path={"home"} element={<Home />} />
                <Route path={"about"} element={<About />} />
                <Route path={"explore/:item"} element={<Explore />} />
                <Route path={"info/:category/:slug"} element={<Info />} />
                <Route path={"how-to-reach"} element={<HowToReach />} />
                <Route path={"tourist-info"} element={<TouristInfo />} />
                <Route path={"events"} element={<Events />} />
            </>
        )
    }
    function renderRoutes() {
        return Object.keys(translations).map((lang_code, id) => {
            return (
                <>
                    {/* Generate url by language. Ex: test.com/en, test.com/en */}
                    <Route key={id} path={lang_code}>
                        {routes()}
                    </Route>
                </>
            );
        })
    }

    useEffect(() => {
        // parse language code from url. ex: test.com/tr/home -> result: tr
        const lang = loc.pathname.split(/\/([a-z]{2})(?![^\/])/gm)[1]
        // check lang index
        const i = Object.keys(translations).findIndex(f => f === lang)
        // if language different than known language
        if (i !== -1 && i18n.language !== lang) {
            // change language
            i18n.changeLanguage(lang)
        }
    }, [loc, i18n])

    return (
        <Suspense fallback={<div className="suspense-loader"><div>
            <PageLoader />
        </div></div>
        }>
            <Routes>
                <Route element={<WebLayout />}>
                    {/* If the user visits test.com, redirect them to the appropriate page for their language. For example test.com -> test.com/en */}
                    <Route path={"/"} element={<Navigate to={`${i18n.language}`} replace />} />
                    {renderRoutes()}
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Routes>
        </Suspense>
    );
}

export default App;

import React from 'react'
import { Spinner } from 'react-bootstrap'

const PageLoader = () => {
    return (
        <div className="page-loader">
            <div className="loader">
                <Spinner animation="border" variant="warning" />
            </div>
        </div>
    )
}

export default PageLoader
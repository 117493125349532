import axios from 'axios'
import { getLocalStorage } from "helpers/LocalStorage";

const service = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    //mode: 'cors',
    // headers: { 
    //     'Content-Type': 'application/jsonp',
    //     'Access-Control-Allow-Origin': '*'
    // },
    timeout: 60000
})

const ENTRY_ROUTE = '/auth/home'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

service.interceptors.request.use(config => {
    const jwtToken = getLocalStorage('kk_token')

    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = 'Bearer ' + jwtToken
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
        //history.push(ENTRY_ROUTE)
    }

    return config
}, error => {
    // error goes here
    return Promise.reject(error)
})

service.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    if (error.response.status === 401) {
        //unsetLocalStorage()
        //localStorage.removeItem(AUTH_TOKEN);
        //history.push(ENTRY_ROUTE)

        return error.response.data.message = 'Session closed ....you have been logged out!'
    }
    if (error.response.status === 403) {
        return error.response.data.message = 'Authentication Fail'
    }
    if (error.response.status === 404) {
        return error.response.data.message = 'Not Found'
    }
    if (error.response.status === 500) {
        return error.response.data.message = 'Internal Server Error'
    }
    if (error.response.status === 508) {
        return error.response.data.message = 'Time Out'
    }
    return Promise.reject(error)
})

export default service
import AxiosIn from 'helpers/AxiosInterceptor'
const WebServices = {}

WebServices.FetchHomeData = function () {
    return AxiosIn({
        url: `/homepages?populate=image`,
        method: 'get'
    })
}
WebServices.FetchMapData = function (category) {
    return AxiosIn({
        url: `${category}?sort=place_name:asc&pagination[page]=1&pagination[pageSize]=100`,
        method: 'get'
    })
}
WebServices.PostGetInTouch = function (formData) {
    return AxiosIn({
        url: `/contact-details`,
        method: 'post',
        data: formData
    })
}
WebServices.FetchPopularPlaces = function (category) {
    return AxiosIn({
        url: `/${category}?populate=logo&sort=place_name:asc&pagination[page]=1&pagination[pageSize]=100`,
        method: 'get'
    })
}
WebServices.FetchPlaceInfo = function (category, slug) {
    let keyword = ""
    if (category === "temples") {
        keyword = "temple"
    }
    else if (category === "churches") {
        keyword = "church"
    }
    else if (category === "mosques") {
        keyword = "mosque"
    }
    else if (category === "beaches") {
        keyword = "beach"
    }
    else if (category === "eco-tourisms") {
        keyword = "eco_tourism"
    }
    else if (category === "harbours") {
        keyword = "harbour"
    }
    else if (category === "parks-and-forts") {
        keyword = "park_and_fort"
    }
    // let key = category.slice(0, -1);
    // let keyword = key.replaceAll("-", "_");
    return AxiosIn({
        url: `/${category}?filters[slug][$eq]=${slug}&populate[${keyword}_galleries][populate]=image&&populate[${keyword}_timings][populate]&populate[${keyword}_entry_fees][populate]`,
        method: 'get'
    })
}

export default WebServices
import React from 'react'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import WebHeaderAlt from './utilities/WebHeaderAlt'
import WebFooter from './utilities/WebFooter'

const WebLayout = ({children}) => {
    const location = useLocation();

    return (
        <div className="web-layout">
            {location.pathname === '/ta/home' || location.pathname === '/en/home' ? null : <WebHeaderAlt />}
            <Outlet context={"en"}/>
            <WebFooter />
        </div>
    )
}

export default WebLayout